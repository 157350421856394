import React from 'react';
import { Avatar, Typography, Divider, List, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './About.css';

const { Title, Paragraph, Text, Link } = Typography;

const skills = [
    'React & Redux',
    'Ant Design',
    'JavaScript & TypeScript',
    'CSS3 & HTML5',
    'Responsive Web Design',
];

const About = () => {
    return (
        <div className="about-container">
            {/* Cover Image */}
            <div className="cover-image">
                <img src="cover_colored.jpg" alt="Cover" />
            </div>

            {/* Profile Section */}
            <div className="profile-section">
                <Avatar src="profile.jpg" size={120} className="profile-avatar" />
                <Title level={2} className="name-title">Xinhe Zhou</Title>
                <Paragraph className="about-description">
                    我不知道你想写点啥但为了占位还是要先写点啥. Passionate developer with a focus on IC technologies and design. Experienced in dancing and enthusiastic about sports!
                </Paragraph>
                <Paragraph className="location">
                    <UserOutlined /> <Text strong>Based in Chengdu, Sichuan</Text>
                </Paragraph>
            </div>

            <Divider />

            {/* Skills Section */}
            <div className="section">
                <Title level={3} className="section-title">Skills</Title>
                <List
                    className="skills-list"
                    dataSource={skills}
                    renderItem={item => (
                        <List.Item className="skill-item">
                            <Text>{item}</Text>
                        </List.Item>
                    )}
                />
            </div>

            <Divider />

            {/* Education Section */}
            <div className="section">
                <Title level={3} className="section-title">Education</Title>
                <Paragraph className="education-description">
                    Bachelor of Science in [Your University Name]
                </Paragraph>
            </div>

            <Divider />

            {/* Professional Experience Section */}
            <div className="section">
                <Title level={3} className="section-title">Professional Experience</Title>
                <Paragraph className="experience-description">
                    <Text strong>Lead Front-End Developer at TechSolutions Inc. (2018-Present)</Text>
                    <ul>
                        <li>Developed and maintained code for in-house and client websites primarily using HTML, CSS, Sass, JavaScript, and jQuery.</li>
                        <li>Managed a team of developers to create high-quality, reusable code and to optimize web-based applications for maximum speed and scalability.</li>
                    </ul>
                    <Text strong>Junior Front-End Developer at WebCreatives Co. (2015-2018)</Text>
                    <ul>
                        <li>Assisted in the development of user interface components using React.js concepts and workflows such as Redux, Data Structure Libraries, and React Hooks.</li>
                        <li>Ensured the technical feasibility of UI/UX designs.</li>
                    </ul>
                </Paragraph>
            </div>

            <Divider />

            {/* Personal Life Section */}
            <div className="section">
                <Title level={3} className="section-title">Personal Life</Title>
                <Paragraph className="personal-life-description">
                    I enjoy traveling, dancing, and playing sports. I'm also a big fan of the outdoors and love hiking.
                    <br />
                    - My traveling blog can be found <Link href="https://www.example.com" target="_blank">here</Link>.
                    <br />
                    - Here is my dancing video <Link href="https://www.example.com" target="_blank">here</Link>.
                    <br />
                    - I'm also a Bilibili creator; my channel can be found <Link href="https://www.example.com" target="_blank">here</Link>.
                </Paragraph>
                Here is a video in bilibili for test.
                <div className="video-container">
                    <iframe
                        title="Bilibili test video"
                        src="//player.bilibili.com/player.html?isOutside=true&aid=1201624427&bvid=BV1ZF4m1V7K1&cid=1462983357&p=1"
                        allowFullScreen={true}></iframe>
                </div>
            </div>

            <Divider />

            {/* Download Resume Button */}
            <div className="download-button-container">
                <Button title="Bilibili test video" type="primary" href="resume-zhouzhou.pdf" target="_blank" className="download-button">
                    Download Resume
                </Button>
            </div>
        </div>
    );
};

export default About;
