import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Typography, Avatar, Row, Col, Divider, Tag } from 'antd';
import { UserOutlined, CalendarOutlined, FolderOutlined } from '@ant-design/icons';
import './BlogDetail.css';

const { Content } = Layout;
const {  Text } = Typography;

const BlogDetail = () => {
    const { id } = useParams(); // 获取动态参数 id

    const posts = [{
        id: 1,
        title: '爱乐之城, 光与影',
        author: 'Xinhe Zhou',
        date: '2024年9月16日',
        content: `
      <p>Lala Land，一首写给梦想的情诗，一曲献给爱情的旋律。一幕幕光影流转间，如同两颗孤独而闪亮的灵魂，在城市的夜空中轻舞，忽远忽近。</p>
      <p>在塞巴斯汀的琴键下，我听见了时间流淌的声音，那是梦想的絮语，是无数个无眠夜里的低语。米娅的眼眸中，燃烧着追寻的火焰，那是一种对未来的渴望，一种不被理解的孤勇。</p>
      <h2>光与影的交错</h2>
      <p>洛杉矶的霓虹灯光，像是一场永不谢幕的表演。每一个镜头都像是一幅画卷，画里有流动的音乐，温暖的光影，和一种无法言说的孤独。它们在静默中歌唱，在尘世的喧嚣中轻轻诉说。</p>
      <blockquote>或许，生活本就是一场表演，时间是唯一的导演。</blockquote>
      <p>米娅与塞巴斯汀相遇、相知、相爱的过程，就像是一场命运的安排。他们在彼此的世界里短暂地燃烧，却又在最美的时刻转身离开。仿佛只有这样，那种短暂的美好才会在心底深处，永远不褪色。</p>
      <h2>梦醒时分</h2>
      <p>电影结束的那一刻，如大梦初醒，心中涌动着淡淡的酸楚与不舍。或许，每个人心中都有一座自己的“爱乐之城”，在那里，我们追寻，迷失，爱过，痛过，但最终都选择了继续前行。</p>
    `,
        tags: ['电影', '感想', '爱乐之城'],
        category: '艺术',
        coverImage: 'post-cover4.jpg',
    }];


    // 根据 id 获取对应的帖子
    const post = posts.find(post => post.id === parseInt(id));

    if (!post) {
        return <p>找不到对应的文章。</p>;
    }
    return (
        <Layout className="blog-detail-layout">
            <Content className="blog-detail-content">
                {/* Cover Image */}
                <div className="cover-image-container">
                    <img src={process.env.PUBLIC_URL + '/' + post.coverImage} alt="Cover" className="cover-image" />
                    <div className="overlay">
                        <h1 className="post-title-detail">{post.title}</h1>
                    </div>
                </div>

                {/* Post Meta */}
                <Row className="post-meta" justify="center">
                    <Col xs={24} sm={20} md={16} lg={12}>
                        <div className="author-info">
                            <Avatar size="large" icon={<UserOutlined />} />
                            <Text className="author-name">{post.author}</Text>
                            <Divider type="vertical" />
                            <CalendarOutlined className="icon" />
                            <Text className="post-date">{post.date}</Text>
                            <Divider type="vertical" />
                            <FolderOutlined className="icon" />
                            <Text className="post-category">{post.category}</Text>
                        </div>
                    </Col>
                </Row>

                {/* Post Content */}
                <Row justify="center">
                    <Col xs={24} sm={20} md={16} lg={12}>
                        <div
                            className="post-content"
                            dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                        {/* Tags */}
                        <div className="post-tags">
                            {post.tags.map(tag => (
                                <Tag key={tag}>{tag}</Tag>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default BlogDetail;
