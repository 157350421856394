import React from 'react';
import { Layout, Typography, Row, Col, Avatar, Divider, Tag, List, Space, Button } from 'antd';
import { UserOutlined, CalendarOutlined, TagOutlined } from '@ant-design/icons';
import './HomePage.css';
import '../App.css'
import { useNavigate } from 'react-router-dom';

const {  Content, Footer } = Layout;
const { Title, Paragraph } = Typography;


const blogPosts = [
    {
        id: 1,
        title: '爱乐之城, 光与影',
        excerpt: '二十年后的一个傍晚，你开着车(或者车载着你)在回家的路上，突然扬声器里开始放起这首电影里的旋律，听到第一个音符的一瞬间，所有的情绪全都涌上来，就好像二十年前的那个夜晚你第一次听到它们的时候那样...',
        author: 'Xinhe Zhou',
        date: '2021年10月10日',
        tags: ['Redis', '数据库', '事务'],
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        coverImage: 'post-cover4.jpg',
    },
    {
        id: 2,
        title: '寒假赶火车',
        excerpt: 'Voter turnout among college students has skyrocketed in the past decade — a trend that Stephanie Kurtzman, the Peter G. Sortino Director of Washington University in St. Louis’ Gephardt Institute for Civic and Community Engagement, expects to continue in the upcoming election...',
        author: 'Jane Smith',
        date: '2021年9月15日',
        tags: ['Redis', '数据分析'],
        avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
        coverImage: 'post-cover1.jpg',
    },
    {
        id: 3,
        title: 'City of Star',
        excerpt: 'Redis支持五种数据类型：string（字符串），hash（哈希），list（列表），set（集合），zset（有序集合）...',
        author: 'Alice Johnson',
        date: '2021年8月20日',
        tags: ['Redis', '优化'],
        avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
        coverImage: 'post-cover3.jpg',
    },
    // Add more blog posts as needed
];

const recentPosts = [
    'Understanding Async/Await in JavaScript',
    'Introduction to Docker and Containerization',
    'Building RESTful APIs with Node.js and Express',
];

const popularPosts = [
    'Mastering React Hooks',
    'A Guide to CSS Flexbox',
    'Improving Web Performance',
];

const HomePage = () => {

    const navigate = useNavigate(); // 使用 useNavigate 钩子
    const handlePostListButtonClick = () => {
        navigate('/postlist'); // 跳转到目标路径，
    };
    const handleAboutButtonClick = () => {
        navigate('/about'); // 跳转到目标路径，
    };

    return (
        <Layout className="homepage-layout">


            {/* Cover Section */}
            <Content className="cover-section">
                <div className="overlay">
                    <h1 className="name">Xinhe Zhou</h1>
                    <Paragraph className="cover-subtitle">生来做码字的诗人</Paragraph>
                    <Button onClick={handlePostListButtonClick} type="primary" size="large" className="cta-button">Explore Articles</Button>
                    <Button style={{margin:10}} onClick={handleAboutButtonClick} type="primary" size="large" className="cta-button">About Me</Button>

                </div>
            </Content>

            {/* Main Content Section */}
            <Content className="content-section">
                <Row gutter={[16, 16]}>
                    {/* Blog Post Column */}
                    <Col xs={24} sm={24} md={16}>
                        <Title level={2} className="section-title">Latest Articles</Title>
                        <List
                            itemLayout="vertical"
                            size="large"
                            dataSource={blogPosts}
                            renderItem={post => (
                                <List.Item
                                    key={post.title}
                                    className="blog-post-item"
                                    actions={[
                                        <Space key="info">
                                            <CalendarOutlined /> {post.date}
                                            <TagOutlined /> {post.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
                                        </Space>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={<a href="" className="blog-post-title">{post.title}</a>}
                                        description={<Paragraph className="blog-post-description">{post.excerpt}</Paragraph>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>

                    {/* Sidebar Section */}
                    <Col xs={24} sm={24} md={8}>
                        <div className="sidebar">
                            <Title level={4}>ABOUT ME</Title>
                            <Avatar src='profile.jpg' size={80} icon={<UserOutlined />} className="sidebar-avatar" />
                            <Paragraph className="sidebar-about">
                                写写代码，听听音乐，读读书。
                            </Paragraph>
                            <Divider />

                            <Title level={4}>FEATURED TAGS</Title>
                            <div className="tag-list">
                                <Tag>IC</Tag>
                                <Tag>Dance</Tag>
                                <Tag>Reading</Tag>
                                <Tag>Travel</Tag>
                                <Tag>Sports</Tag>
                            </div>
                            <Divider />

                            <Title level={4}>RECENT POSTS</Title>
                            <List
                                size="small"
                                dataSource={recentPosts}
                                renderItem={item => (
                                    <List.Item key={item}>
                                        <a href="#" className="recent-post-link">{item}</a>
                                    </List.Item>
                                )}
                            />
                            <Divider />

                            <Title level={4}>POPULAR ARTICLES</Title>
                            <List
                                size="small"
                                dataSource={popularPosts}
                                renderItem={item => (
                                    <List.Item key={item}>
                                        <a href="#" className="popular-post-link">{item}</a>
                                    </List.Item>
                                )}
                            />
                            <Divider />

                            <Title level={4}>SUBSCRIBE</Title>
                            <Button type="primary" block>Subscribe to Newsletter</Button>
                        </div>
                    </Col>
                </Row>
            </Content>

            {/* Footer */}
            <Footer className="footer">
                © 2024 周心荷. All rights reserved.
            </Footer>
        </Layout>
    );
};

export default HomePage;
