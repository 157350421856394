import RoutesConfig from './RoutesConfig';
import {ConfigProvider} from "antd";


function App() {
  return (
      <ConfigProvider
        theme={{
            token: {
                colorPrimary: '#b37feb',
                borderRadius: 2,
                colorBgContainer: '#f9f0ff',

            },
            components: {
                Layout:{
                    headerBg: '#f9f0ff',
                }
            }


        }}

      >
          <RoutesConfig />
      </ConfigProvider>
  );
}


export default App;
