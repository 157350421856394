import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import BlogEditor from "./Pages/BlogEditor";
import HomePage from "./Pages/HomePage";
import BlogDetail from "./Pages/BlogDetail";
import BlogList from "./Pages/BlogList";
import BlogLayout from "./Layout";
function RoutesConfig () {
  return (

    <Router>
        <BlogLayout>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route path="/write" element={<BlogEditor />} />
                <Route path="/post/:id" element={<BlogDetail />} />
                <Route path="/postlist" element={<BlogList />} />
            </Routes>
        </BlogLayout>
    </Router>
  );
}
export default RoutesConfig;

