import {React, useState} from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import {useNavigate} from "react-router-dom";
import { HomeOutlined, UnorderedListOutlined, UserOutlined, MenuOutlined} from '@ant-design/icons';
import './BlogLayout.css'

const { Header, Content } = Layout;



const BlogLayout = ({ children }) => {
    // 定义菜单项
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const menuItems = [
        {
            label: 'Home',
            key: 'home',
            icon: <HomeOutlined />,
            onClick: () => {
                navigate('/');
                onClose();
            },
        },
        {
            label: 'Posts',
            key: 'postlist',
            icon: <UnorderedListOutlined />,
            onClick: () => {
                navigate('/postlist');
                onClose();
            },
        },
        {
            label: 'About',
            key: 'about',
            icon: <UserOutlined />,
            onClick: () => {
                navigate('/about');
                onClose();
            },
        },
    ];



    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Header Section */}
            <Header className="header" style={{ padding: '0 16px', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                {/* Menu Button */}
                <Button
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                    style={{ fontSize: '20px' }}
                />
            </Header>

            {/* Drawer for Menu */}
            <Drawer
                title="Menu"
                placement="right"
                onClose={onClose}
                open={visible}
                bodyStyle={{
                    padding: 0, // Remove padding for a more modern look
                }}
                headerStyle={{
                    borderBottom: '1px solid #f0f0f0', // Subtle divider
                    padding: '16px',
                }}
            >
                <Menu
                    mode="inline"
                    items={menuItems} // 使用 items 属性来定义菜单项
                    style={{
                        borderRight: 'none', // Remove border for a cleaner look
                    }}
                />
            </Drawer>


            {/* Content Section */}
            <Content className="content">
                <div className="site-layout-content">
                    {children}
                </div>
            </Content>
        </Layout>
    );
};

export default BlogLayout;
