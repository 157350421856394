import React from 'react';
import { Layout, Typography, List, Avatar, Tag, Row, Col, Input } from 'antd';
import { CalendarOutlined, TagOutlined, SearchOutlined } from '@ant-design/icons';
import './BlogList.css';

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Search } = Input;

const BlogList = () => {
    const blogPosts = [
        {
            id: 1,
            title: '爱乐之城, 光与影',
            excerpt: '二十年后的一个傍晚，你开着车(或者车载着你)在回家的路上，突然扬声器里开始放起这首电影里的旋律，听到第一个音符的一瞬间，所有的情绪全都涌上来，就好像二十年前的那个夜晚你第一次听到它们的时候那样...',
            author: 'Xinhe Zhou',
            date: '2021年10月10日',
            tags: ['Redis', '数据库', '事务'],
            avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
            coverImage: 'post-cover4.jpg',
        },
        {
            id: 2,
            title: '寒假赶火车',
            excerpt: 'Voter turnout among college students has skyrocketed in the past decade — a trend that Stephanie Kurtzman, the Peter G. Sortino Director of Washington University in St. Louis’ Gephardt Institute for Civic and Community Engagement, expects to continue in the upcoming election...',
            author: 'Jane Smith',
            date: '2021年9月15日',
            tags: ['Redis', '数据分析'],
            avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
            coverImage: 'post-cover1.jpg',
        },
        {
            id: 3,
            title: 'City of Star',
            excerpt: 'Redis支持五种数据类型：string（字符串），hash（哈希），list（列表），set（集合），zset（有序集合）...',
            author: 'Alice Johnson',
            date: '2021年8月20日',
            tags: ['Redis', '优化'],
            avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
            coverImage: 'post-cover3.jpg',
        },
        // Add more blog posts as needed
    ];

    return (
        <Layout className="blog-list-layout">
            <Content className="blog-list-content">
                {/* Page Title */}
                <div className="page-header">
                    <Title className="page-title">All Blog Posts</Title>
                    <Paragraph className="page-subtitle">
                        Explore our latest articles on various topics.
                    </Paragraph>
                </div>

                {/* Search Bar */}
                <Row justify="center" className="search-row">
                    <Col xs={22} sm={16} md={12} lg={8}>
                        <Search
                            placeholder="Search blog posts"
                            enterButton={<SearchOutlined />}
                            size="large"
                            className="search-input"
                        />
                    </Col>
                </Row>

                {/* Blog Posts List */}
                <div className="blog-list">
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                            pageSize: 5,
                        }}
                        dataSource={blogPosts}
                        renderItem={post => (
                            <List.Item
                                key={post.id}
                                className="blog-list-item"
                                extra={
                                    <img
                                        width={200}
                                        alt="Cover"
                                        src={post.coverImage}
                                        className="post-cover-image"
                                    />
                                }
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={post.avatar} size="large" />}
                                    title={
                                        <a href={`/post/${post.id}`} className="post-title">
                                            {post.title}
                                        </a>
                                    }
                                    description={
                                        <div className="post-meta">
                                            <CalendarOutlined className="icon" /> {post.date}
                                        </div>
                                    }
                                />
                                <Paragraph className="post-excerpt">{post.excerpt}</Paragraph>
                                <div className="post-tags">
                                    {post.tags.map(tag => (
                                        <Tag key={tag} icon={<TagOutlined />}>
                                            {tag}
                                        </Tag>
                                    ))}
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default BlogList;
