import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // 导入 React Quill
import 'react-quill/dist/quill.snow.css'; // 导入 Quill 相关的 CSS
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';

function BlogEditor() {
  const [form] = Form.useForm();
  const [content, setContent] = useState('test');
  const quillRef = React.useRef(null);

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post("http://localhost:3000/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data.filePath; // 返回文件路径
    } catch (error) {
      message.error('文件上传失败');
      return "";
    }
  };

  const handleContentChange = (content) => {
    console.log('Current editor content:', content); // 在控制台输出 value
    setContent(content); // 更新 state
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    // 此处应包含将数据发送到服务器的代码
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video','formula'],
      ['clean']
    ],
  }
  const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image','code','blockquote'
      ]

  return (
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="title" rules={[{ required: true, message: '请输入标题' }]}>
          <Input placeholder="请输入标题" />
        </Form.Item>
        <Form.Item name="text-editor">
          <ReactQuill theme="snow" value={content} onChange={handleContentChange} modules={modules}
                        formats={formats}
                      placeholder={"记录生活，分享快乐～"}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" >
            提交
          </Button>
        </Form.Item>
      </Form>
  );
}

export default BlogEditor;
